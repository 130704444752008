<template>
  <auth-layout>
    <section class="text-center">
      <h1 class="text-2pxl lg:text-2xl font-mulish font-black uppercase">
        {{ APP_NAME }} STAFF/PENSION VERIFICATION
      </h1>
      <h5 class="text-sm lg:text-base font-semibold text-oneGray">
        Do you have a login details to start the verification exercise?
      </h5>
    </section>

    <form @submit.prevent="register">
      <div class="mt-4">
        <label for="verificationCode">
          Verification Number<span class="text-red-400">*</span>
        </label>
        <input
          id="verificationCode"
          type="text"
          class="input"
          v-model="form.verificationCode"
          placeholder="Enter your verification number"
          maxlength="10"
        />
      </div>

      <div class="mt-4">
        <label for="salaryAccountNumber">
          Salary Account or Pension Account<span class="text-red-400">*</span>
        </label>
        <input
          id="salaryAccountNumber"
          type="text"
          class="input"
          v-model="form.salaryAccountNumber"
          placeholder="Enter your salary account number"
          maxlength="10"
        />
      </div>

      <div class="mt-4">
        <label for="phoneNumber">
          Phone Number<span class="text-red-400">*</span>
        </label>
        <input
          id="phoneNumber"
          type="text"
          class="input"
          v-model="form.phoneNumber"
          placeholder="Enter your phone number"
        />
      </div>

      <div class="mt-4">
        <label for="email">
          Email address<span class="text-red-400">*</span>
        </label>
        <input
          id="email"
          type="email"
          class="input"
          v-model="form.email"
          placeholder="Enter your email address"
        />
      </div>

      <div class="mt-7">
        <button
          type="submit"
          class="bg-oneGreen text-white rounded-lg py-4 text-sm lg:text-2pxl font-semibold w-full flex justify-center items-center gap-4"
          :disabled="regging || !formReady"
        >
          <spinner class="" v-if="regging"></spinner>
          <span>Register</span>
        </button>
      </div>
    </form>

    <section
      class="flex flex-col justify-center items-center mt-9 gap-5 text-xs lg:text-sm font-semibold"
    >
      <p class="">
        Already registered ?
        <router-link to="/" class="text-oneGreen">Log In</router-link>
      </p>
    </section>
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  name: "Signup",

  components: {
    AuthLayout,
  },

  data() {
    return {
      form: {
        verificationCode: null,
        salaryAccountNumber: null,
        email: null,
        phoneNumber: null,
      },
      regging: false,
    };
  },

  computed: {
    emailReady() {
      return (
        (this.form.email &&
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.form.email
          )) ||
        !this.form.email
      );
    },
    phoneReady() {
      return (
        (this.form.phoneNumber &&
          /^(\+[\d]{1,13}|0[\d]{1,11})$/.test(this.form.phoneNumber)) ||
        !this.form.phoneNumber
      );
    },
    formReady() {
      return (
        this.form.verificationCode &&
        this.form.verificationCode.length >= 5 &&
        this.form.salaryAccountNumber &&
        this.form.salaryAccountNumber.length === 10 &&
        this.emailReady &&
        this.phoneReady
      );
    },
  },

  methods: {
    async register() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all required input",
        });
        return;
      }

      try {
        this.regging = true;

        const res = await this.$http.post("/auth/e/register", this.form, {
          headers: { noauth: true },
        });

        this.regging = false;
        if (!res) {
          return;
        }
        const { data } = res;
        this.$store.commit("general/saveUser", {
          user: data.data,
        });

        if (data.data.isActive && data.data.activationCode) {
          this.$swal({
            icon: "success",
            text: "This profile is active kindly Login or Change Password if Forgotten.",
          });
          this.$router.push({ name: "Login" });
          return;
        }

        if (!data.data.isActive && data.data.activationCode) {
          this.$swal({
            icon: "success",
            text: "This profile is Not active kindly Activate Your Account.",
          });
          this.$router.push({ name: "Account Verification" });
          return;
        }

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$router.push({ name: "Account Verification" });
      } catch (error) {
        this.regging = false;
      }
    },
  },
};
</script>

<style scoped>
form {
  @apply mx-auto w-full mt-11 lg:mt-9;
  max-width: 35rem;
}
</style>
