var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AuthHeader'),_c('main',{staticClass:"container mt-20 mx-auto px-4 py-8"},[_c('h1',{staticClass:"text-2xl font-bold mb-4"},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(_vm.PROJECT_NAME))]),_vm._v(" OneID Authenticator Privacy Policy ")]),_c('p',{staticClass:"text-sm text-gray-600 mb-6"},[_vm._v("Effective from January 2024")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)]),_c('AuthFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg mb-8"},[_c('h2',{staticClass:"text-2xl font-semibold text-gray-900 mb-4"},[_vm._v("Introduction")]),_c('p',{staticClass:"text-gray-700 leading-relaxed"},[_vm._v(" OneID Synergy Impex Ltd is committed to protecting the privacy and security of your personal information. This privacy policy outlines our practices regarding the collection, use, and disclosure of personal information through our website and online identity verification platform (collectively, the \"Platform\"), in compliance with the Nigeria Data Protection Regulation (NDPR) 2019, Nigeria Data Protection Act (NDPA) 2023, and other relevant privacy laws in Nigeria. This policy also covers our use of biometric data in partnership with third-party providers such as FACEIO for facial authentication and identity verification. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg mb-8"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900 mb-4"},[_vm._v(" 1. Information We Collect ")]),_c('p',{staticClass:"text-gray-700 leading-relaxed mb-4"},[_vm._v(" We collect information that identifies, relates to, describes, or is capable of being associated with you (\"Personal Information\"), including but not limited to: ")]),_c('ul',{staticClass:"list-disc list-inside text-gray-700"},[_c('li',[_vm._v("Names, email addresses, phone numbers, and dates of birth")]),_c('li',[_vm._v(" Biometric information (such as facial recognition data) for identity verification purposes ")]),_c('li',[_vm._v("IP addresses, browser types, and access times")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg mb-8"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900 mb-4"},[_vm._v(" 2. How We Use Your Information ")]),_c('p',{staticClass:"text-gray-700 leading-relaxed mb-4"},[_vm._v(" Your Personal Information is used for the following purposes: ")]),_c('ul',{staticClass:"list-disc list-inside text-gray-700"},[_c('li',[_vm._v("To provide and improve our identity verification services")]),_c('li',[_vm._v(" To comply with applicable legal requirements and regulatory obligations ")]),_c('li',[_vm._v("For customer support and to respond to your inquiries")]),_c('li',[_vm._v("For internal research and development purposes")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg mb-8"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900 mb-4"},[_vm._v(" 3. Face Data Collection and Usage ")]),_c('p',{staticClass:"text-gray-700 leading-relaxed mb-4"},[_vm._v(" As part of our identity verification process, we use FACEIO's facial recognition services. The face data used in this process is handled according to strict privacy and security measures: ")]),_c('ul',{staticClass:"list-disc list-inside text-gray-700"},[_c('li',[_c('strong',[_vm._v("Face Data Retention:")]),_vm._v(" We do not retain face data beyond the immediate verification process. FACEIO's facial recognition engine stores only facial hashes (encrypted biometric templates) and not the raw face data. These hashes are stored only for as long as is required to fulfill the purpose of authentication or enrollment. ")]),_c('li',[_c('strong',[_vm._v("Face Data Storage Duration:")]),_vm._v(" Face data is not stored indefinitely. It is retained only until the user deletes it or the purpose of its collection (such as authentication) has been met. ")]),_c('li',[_c('strong',[_vm._v("Third-Party Access:")]),_vm._v(" FACEIO, our third-party provider, acts as a data processor for facial recognition. They do not have access to or store raw biometric data, and facial hashes are encrypted. FACEIO does not share this data with any independent third parties unless required by law. ")]),_c('li',[_c('strong',[_vm._v("Data Sharing:")]),_vm._v(" We do not share your facial data with other third parties except for law enforcement when required by legal obligations. FACEIO also follows these strict guidelines and does not sell or lease biometric data. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg mb-8"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900 mb-4"},[_vm._v(" 4. Data Retention ")]),_c('p',{staticClass:"text-gray-700 leading-relaxed mb-4"},[_vm._v(" You may permanently and irreversibly delete your data at any time by contacting us. We store only a template (facial hash) for reference purposes, which can be deleted immediately upon your request. This data is encrypted and accessible only by authorized administrators. ")]),_c('ul',{staticClass:"list-disc list-inside text-gray-700"},[_c('li',[_c('strong',[_vm._v("FACEIO Data Retention Policy:")]),_vm._v(" FACEIO complies with data protection laws, including the Illinois Biometric Information Privacy Act (BIPA), requiring that facial hashes be deleted within three years of your last interaction with the application, or earlier, depending on the purpose being fulfilled. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg mb-8"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900 mb-4"},[_vm._v(" 5. Data Security ")]),_c('p',{staticClass:"text-gray-700 leading-relaxed"},[_vm._v(" We implement reasonable security measures to protect your Personal Information from unauthorized access, disclosure, alteration, or destruction. However, no internet-based platform can be 100% secure, and we cannot guarantee the security of your Personal Information. FACEIO uses advanced encryption to ensure the security of your facial hashes during transmission and storage. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg mb-8"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900 mb-4"},[_vm._v(" 6. Third-Party Services ")]),_c('p',{staticClass:"text-gray-700 leading-relaxed"},[_vm._v(" FACEIO is our third-party provider for facial recognition. FACEIO does not retain or store your raw biometric data and acts solely as a service provider processing facial hashes on our behalf. All data processing by FACEIO complies with relevant data protection laws, and no facial data is shared with any additional third parties. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg mb-8"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900 mb-4"},[_vm._v("7. Your Rights")]),_c('p',{staticClass:"text-gray-700 leading-relaxed mb-4"},[_vm._v(" Under the NDPR, NDPA, and other applicable laws, you have rights concerning your Personal Information, including: ")]),_c('ul',{staticClass:"list-disc list-inside text-gray-700"},[_c('li',[_vm._v(" The right to access, correct, delete, or restrict the use of your Personal Information ")]),_c('li',[_vm._v(" The right to delete facial hashes and other biometric data at any time ")])]),_c('p',{staticClass:"text-gray-700 leading-relaxed"},[_vm._v(" To exercise these rights, please contact us using the information below. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg mb-8"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900 mb-4"},[_vm._v(" 8. Changes to Our Privacy Policy ")]),_c('p',{staticClass:"text-gray-700 leading-relaxed"},[_vm._v(" We may update this privacy policy from time to time. The effective date at the top of this policy will be updated to reflect any changes. We encourage you to review our privacy policy periodically. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"bg-white p-6 shadow rounded-lg"},[_c('h2',{staticClass:"text-xl font-semibold text-gray-900 mb-4"},[_vm._v("9. Contact Us")]),_c('p',{staticClass:"text-gray-700 leading-relaxed"},[_vm._v(" If you have any questions about this privacy policy or our data practices, please contact us at: ")]),_c('ul',{staticClass:"list-none text-gray-700"},[_c('li',[_c('strong',[_vm._v("Email:")]),_vm._v(" info@oneid.ng")]),_c('li',[_c('strong',[_vm._v("Phone:")]),_vm._v(" 09025579505")])])])
}]

export { render, staticRenderFns }