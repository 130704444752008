<template>
  <auth-layout>
    <section class="text-center">
      <h1 class="text-2pxl lg:text-2xl font-mulish font-black uppercase">
        {{ APP_NAME }} Staff VERIFICATION
      </h1>
      <h5 class="text-sm lg:text-base font-semibold text-oneGray">
        Create password to login
      </h5>
    </section>

    <form @submit.prevent="createPassword">
      <div class="">
        <label for="password">Password</label>
        <input
          id="password"
          type="password"
          v-model="form.password"
          class="input"
          placeholder="enter password"
        />
        <small class="text-xs text-red-500 font-semibold" v-if="passwordError">
          A minimum length of 6
        </small>
      </div>

      <div class="mt-4">
        <label for="confirmPassword">Confirm Password</label>
        <input
          id="confirmPassword"
          type="password"
          v-model="form.confirmPassword"
          class="input"
          placeholder="re-enter password"
        />
      </div>

      <div class="mt-7">
        <button
          type="submit"
          class="bg-oneGreen text-white rounded-lg py-4 text-sm lg:text-2pxl font-semibold w-full flex justify-center items-center gap-4"
          :disabled="creating || !formReady"
        >
          <spinner class="" v-if="creating"></spinner>
          <span>{{ actioned }} Password</span>
        </button>
      </div>
    </form>
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  name: "CreatePassword",

  components: {
    AuthLayout,
  },

  data() {
    return {
      form: {
        activationCode: this.$store.state.general.user.activationCode,
        userId: this.$store.state.general.user.id,
        password: null,
        confirmPassword: null,
      },
      creating: false,
      actioned: "Create",
      nextAcn: "reset",
    };
  },

  created() {
    const { acn } = this.$route.query;
    this.nextAcn = acn;
    this.actioned = acn === "reset" ? "Change" : "Create";
  },

  computed: {
    passwordValid() {
      return this.form.password && this.form.password.length > 5;
    },
    passwordError() {
      return Boolean(this.form.password) & !this.passwordValid;
    },
    formReady() {
      return (
        this.passwordValid && this.form.password === this.form.confirmPassword
      );
    },
  },

  methods: {
    async createPassword() {
      try {
        this.creating = true;

        const res = await this.$http.post("/auth/e/reset-password", this.form, {
          headers: { noauth: true },
        });

        this.creating = false;
        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.creating = false;
      }
    },
  },
};
</script>

<style scoped>
form {
  @apply mx-auto w-full mt-11 lg:mt-14;
  max-width: 35rem;
}
</style>
