<template>
  <auth-layout>
    <section
      class="flex items-center bg-oneGreen/10 gap-4 py-5 px-5 rounded-lg mt-4"
    >
      <img
        src="@/assets/images/icons/quill_info.svg"
        alt="icon"
        class="w-6 h-6 lg:w-10 lg:h-10"
      />
      <p class="text-xs lg:text-2pxl font-semibold text-oneGreen">
        Activation codes has been sent to your email and phone number.
      </p>
    </section>

    <form @submit.prevent="verifyAccount">
      <div class="mt-4">
        <label for="phoneCode"> Enter the code sent to your phone </label>
        <div class="code-div">
          <input
            id="phoneCode"
            type="text"
            class="input-code"
            v-model="form.phoneCode"
            placeholder="Enter phone activation code"
          />
          <button
            type="button"
            @click="resend('phoneTimerCount')"
            :disabled="!phoneResendready"
            class="text-oneGreen px-1 py-3"
          >
            <span v-if="phoneResendready" class="underline text-xs lg:text-sm">
              Resend
            </span>
            <span v-else class="text-xs">00:{{ phoneTimerCount }}</span>
          </button>
        </div>
      </div>

      <div class="flex justify-between items-center gap-3 mt-8">
        <hr class="w-5/12 h-px bg-litgray" />

        <span class="text-litgray font-semibold text-xs lg:text-sm">OR</span>

        <hr class="w-5/12 h-px bg-litgray" />
      </div>

      <div class="mt-8">
        <label for="emailCode">
          Enter the code sent to your email address
        </label>
        <div class="code-div">
          <input
            id="emailCode"
            type="text"
            class="input-code"
            v-model="form.emailCode"
            placeholder="Enter email activation code"
          />
          <button
            type="button"
            @click="resend('emailTimerCount')"
            :disabled="!emailResendready"
            class="text-oneGreen px-1 py-3"
          >
            <span v-if="emailResendready" class="underline text-xs lg:text-sm">
              Resend
            </span>
            <span v-else class="text-xs">00:{{ emailTimerCount }}</span>
          </button>
        </div>
      </div>

      <div class="mt-7">
        <button
          type="submit"
          class="bg-oneGreen text-white rounded-lg py-4 text-sm lg:text-2pxl font-semibold w-full flex justify-center items-center gap-4"
          :disabled="verifying || !formReady"
        >
          <spinner class="" v-if="verifying"></spinner>
          <span>Activate Account</span>
        </button>
      </div>
    </form>
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";

export default {
  name: "AccountVerification",

  components: {
    AuthLayout,
  },

  data() {
    const resendDelay = 30;
    return {
      form: {
        // userId: this.$store.state.general.user.id,
        userId: this.$store.getters["general/userId"],
        phoneCode: null,
        emailCode: null,
      },
      verifying: false,
      delay: resendDelay,
      phoneTimerCount: resendDelay,
      emailTimerCount: resendDelay,
    };
  },

  computed: {
    phoneResendready() {
      return this.phoneTimerCount <= 0;
    },
    emailResendready() {
      return this.emailTimerCount <= 0;
    },
    formReady() {
      return (
        (this.form.emailCode && this.form.emailCode.length === 6) ||
        (this.form.phoneCode && this.form.phoneCode.length === 6)
      );
    },
  },

  created() {
    this.countDownTimer("phoneTimerCount");
    this.countDownTimer("emailTimerCount");
  },

  methods: {
    countDownTimer(timer) {
      if (this[timer] > 0) {
        setTimeout(() => {
          this[timer] -= 1;
          this.countDownTimer(timer);
        }, 1000);
      }
    },
    async verifyAccount() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all required input",
        });
        return;
      }

      try {
        this.verifying = true;

        const res = await this.$http.post("/auth/e/activate", this.form, {
          headers: { noauth: true },
        });

        this.verifying = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("general/saveUser", {
          user: data.data,
        });

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this.$router.push({
          name: "Create Password",
          query: { acn: "create" },
        });
      } catch (error) {
        this.verifying = false;
      }
    },
    async resend(el) {
      try {
        this[el] = this.delay;

        const res = await this.$http.post(
          "/auth/e/resend-token",
          {
            userId: this.form.userId,
            type: el === "emailTimerCount" ? "email" : "phone",
          },
          {
            headers: { noauth: true },
          }
        );

        if (!res) {
          return;
        }

        const { data } = res;

        this.$swal({
          icon: "success",
          text: data.message,
        });

        this[el] = this.delay;
        this.countDownTimer(el);
      } catch (error) {
        this.verifying = false;
      }
    },
  },
};
</script>

<style scoped>
section,
form {
  @apply mx-auto w-full;
  max-width: 35rem;
}
form {
  @apply lg:px-0 mt-11 lg:mt-9;
}
</style>
