<template>
  <div>
    <AuthHeader />

    <main class="container mt-20 mx-auto px-4 py-8">
      <h1 class="text-2xl font-bold mb-4">
        <span class="capitalize">{{ PROJECT_NAME }}</span> OneID Authenticator
        Privacy Policy
      </h1>
      <p class="text-sm text-gray-600 mb-6">Effective from January 2024</p>

      <section class="bg-white p-6 shadow rounded-lg mb-8">
        <h2 class="text-2xl font-semibold text-gray-900 mb-4">Introduction</h2>
        <p class="text-gray-700 leading-relaxed">
          OneID Synergy Impex Ltd is committed to protecting the privacy and
          security of your personal information. This privacy policy outlines
          our practices regarding the collection, use, and disclosure of
          personal information through our website and online identity
          verification platform (collectively, the "Platform"), in compliance
          with the Nigeria Data Protection Regulation (NDPR) 2019, Nigeria Data
          Protection Act (NDPA) 2023, and other relevant privacy laws in
          Nigeria. This policy also covers our use of biometric data in
          partnership with third-party providers such as FACEIO for facial
          authentication and identity verification.
        </p>
      </section>

      <section class="bg-white p-6 shadow rounded-lg mb-8">
        <h2 class="text-xl font-semibold text-gray-900 mb-4">
          1. Information We Collect
        </h2>
        <p class="text-gray-700 leading-relaxed mb-4">
          We collect information that identifies, relates to, describes, or is
          capable of being associated with you ("Personal Information"),
          including but not limited to:
        </p>
        <ul class="list-disc list-inside text-gray-700">
          <li>Names, email addresses, phone numbers, and dates of birth</li>
          <li>
            Biometric information (such as facial recognition data) for identity
            verification purposes
          </li>
          <li>IP addresses, browser types, and access times</li>
        </ul>
      </section>

      <section class="bg-white p-6 shadow rounded-lg mb-8">
        <h2 class="text-xl font-semibold text-gray-900 mb-4">
          2. How We Use Your Information
        </h2>
        <p class="text-gray-700 leading-relaxed mb-4">
          Your Personal Information is used for the following purposes:
        </p>
        <ul class="list-disc list-inside text-gray-700">
          <li>To provide and improve our identity verification services</li>
          <li>
            To comply with applicable legal requirements and regulatory
            obligations
          </li>
          <li>For customer support and to respond to your inquiries</li>
          <li>For internal research and development purposes</li>
        </ul>
      </section>

      <section class="bg-white p-6 shadow rounded-lg mb-8">
        <h2 class="text-xl font-semibold text-gray-900 mb-4">
          3. Face Data Collection and Usage
        </h2>
        <p class="text-gray-700 leading-relaxed mb-4">
          As part of our identity verification process, we use FACEIO's facial
          recognition services. The face data used in this process is handled
          according to strict privacy and security measures:
        </p>
        <ul class="list-disc list-inside text-gray-700">
          <li>
            <strong>Face Data Retention:</strong> We do not retain face data
            beyond the immediate verification process. FACEIO's facial
            recognition engine stores only facial hashes (encrypted biometric
            templates) and not the raw face data. These hashes are stored only
            for as long as is required to fulfill the purpose of authentication
            or enrollment.
          </li>
          <li>
            <strong>Face Data Storage Duration:</strong> Face data is not stored
            indefinitely. It is retained only until the user deletes it or the
            purpose of its collection (such as authentication) has been met.
          </li>
          <li>
            <strong>Third-Party Access:</strong> FACEIO, our third-party
            provider, acts as a data processor for facial recognition. They do
            not have access to or store raw biometric data, and facial hashes
            are encrypted. FACEIO does not share this data with any independent
            third parties unless required by law.
          </li>
          <li>
            <strong>Data Sharing:</strong> We do not share your facial data with
            other third parties except for law enforcement when required by
            legal obligations. FACEIO also follows these strict guidelines and
            does not sell or lease biometric data.
          </li>
        </ul>
      </section>

      <section class="bg-white p-6 shadow rounded-lg mb-8">
        <h2 class="text-xl font-semibold text-gray-900 mb-4">
          4. Data Retention
        </h2>
        <p class="text-gray-700 leading-relaxed mb-4">
          You may permanently and irreversibly delete your data at any time by
          contacting us. We store only a template (facial hash) for reference
          purposes, which can be deleted immediately upon your request. This
          data is encrypted and accessible only by authorized administrators.
        </p>
        <ul class="list-disc list-inside text-gray-700">
          <li>
            <strong>FACEIO Data Retention Policy:</strong> FACEIO complies with
            data protection laws, including the Illinois Biometric Information
            Privacy Act (BIPA), requiring that facial hashes be deleted within
            three years of your last interaction with the application, or
            earlier, depending on the purpose being fulfilled.
          </li>
        </ul>
      </section>

      <section class="bg-white p-6 shadow rounded-lg mb-8">
        <h2 class="text-xl font-semibold text-gray-900 mb-4">
          5. Data Security
        </h2>
        <p class="text-gray-700 leading-relaxed">
          We implement reasonable security measures to protect your Personal
          Information from unauthorized access, disclosure, alteration, or
          destruction. However, no internet-based platform can be 100% secure,
          and we cannot guarantee the security of your Personal Information.
          FACEIO uses advanced encryption to ensure the security of your facial
          hashes during transmission and storage.
        </p>
      </section>

      <section class="bg-white p-6 shadow rounded-lg mb-8">
        <h2 class="text-xl font-semibold text-gray-900 mb-4">
          6. Third-Party Services
        </h2>
        <p class="text-gray-700 leading-relaxed">
          FACEIO is our third-party provider for facial recognition. FACEIO does
          not retain or store your raw biometric data and acts solely as a
          service provider processing facial hashes on our behalf. All data
          processing by FACEIO complies with relevant data protection laws, and
          no facial data is shared with any additional third parties.
        </p>
      </section>

      <section class="bg-white p-6 shadow rounded-lg mb-8">
        <h2 class="text-xl font-semibold text-gray-900 mb-4">7. Your Rights</h2>
        <p class="text-gray-700 leading-relaxed mb-4">
          Under the NDPR, NDPA, and other applicable laws, you have rights
          concerning your Personal Information, including:
        </p>
        <ul class="list-disc list-inside text-gray-700">
          <li>
            The right to access, correct, delete, or restrict the use of your
            Personal Information
          </li>
          <li>
            The right to delete facial hashes and other biometric data at any
            time
          </li>
        </ul>
        <p class="text-gray-700 leading-relaxed">
          To exercise these rights, please contact us using the information
          below.
        </p>
      </section>

      <section class="bg-white p-6 shadow rounded-lg mb-8">
        <h2 class="text-xl font-semibold text-gray-900 mb-4">
          8. Changes to Our Privacy Policy
        </h2>
        <p class="text-gray-700 leading-relaxed">
          We may update this privacy policy from time to time. The effective
          date at the top of this policy will be updated to reflect any changes.
          We encourage you to review our privacy policy periodically.
        </p>
      </section>

      <section class="bg-white p-6 shadow rounded-lg">
        <h2 class="text-xl font-semibold text-gray-900 mb-4">9. Contact Us</h2>
        <p class="text-gray-700 leading-relaxed">
          If you have any questions about this privacy policy or our data
          practices, please contact us at:
        </p>
        <ul class="list-none text-gray-700">
          <li><strong>Email:</strong> info@oneid.ng</li>
          <li><strong>Phone:</strong> 09025579505</li>
        </ul>
      </section>
    </main>

    <AuthFooter />
  </div>
</template>

<script>
import AuthHeader from "@/components/auth/Header.vue";
import AuthFooter from "@/components/auth/Footer.vue";

export default {
  name: "Signup",

  components: {
    AuthHeader,
    AuthFooter,
  },

  data() {
    return {
      form: {
        verificationCode: null,
        salaryAccountNumber: null,
        email: null,
        phoneNumber: null,
      },
      regging: false,
    };
  },

  computed: {
    emailReady() {
      return (
        (this.form.email &&
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.form.email
          )) ||
        !this.form.email
      );
    },
    phoneReady() {
      return (
        (this.form.phoneNumber &&
          /^(\+[\d]{1,13}|0[\d]{1,11})$/.test(this.form.phoneNumber)) ||
        !this.form.phoneNumber
      );
    },
    formReady() {
      return (
        this.form.verificationCode &&
        this.form.verificationCode.length >= 5 &&
        this.form.salaryAccountNumber &&
        this.form.salaryAccountNumber.length === 10 &&
        this.emailReady &&
        this.phoneReady
      );
    },
  },

  methods: {
    async register() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all required input",
        });
        return;
      }

      try {
        this.regging = true;

        const res = await this.$http.post("/auth/e/register", this.form, {
          headers: { noauth: true },
        });

        this.regging = false;
        if (!res) {
          return;
        }
        const { data } = res;
        this.$store.commit("general/saveUser", {
          user: data.data,
        });

        if (data.data.isActive && data.data.activationCode) {
          this.$swal({
            icon: "success",
            text: "This profile is active kindly Login or Change Password if Forgotten.",
          });
          this.$router.push({ name: "Login" });
          return;
        }

        if (!data.data.isActive && data.data.activationCode) {
          this.$swal({
            icon: "success",
            text: "This profile is Not active kindly Activate Your Account.",
          });
          this.$router.push({ name: "Account Verification" });
          return;
        }

        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.$router.push({ name: "Account Verification" });
      } catch (error) {
        this.regging = false;
      }
    },
  },
};
</script>

<style scoped>
form {
  @apply mx-auto w-full mt-11 lg:mt-9;
  max-width: 35rem;
}
</style>
