<template>
  <div>
    <AuthHeader />

    <div
      class="background-container"
      :style="{
        '--bgImage':
          'url(' +
          require('@/assets/images/' + PROJECT_NAME + '-shield.png') +
          ')',
      }"
    >
      <div class="inquiry">
        <div class="inquirytext">
          <h3>Got an enquiry?</h3>
          <h6>SEND US A MESSAGE</h6>
        </div>
        <div class="inquiryPhone">
          <p>You can call us through this line(s)</p>
          <p v-html="CONTACT_PHONE"></p>
        </div>
      </div>

      <form @submit.prevent="register" class="form">
        <div class="flex justify-between items-center gap-5">
          <div class="w-full">
            <label for="contactName">
              Your Name<span class="text-red-400">*</span>
            </label>
            <input
              id="contactName"
              v-model="form.contactName"
              type="text"
              class="input"
              placeholder="Enter your name"
              maxlength="30"
            />
          </div>

          <div class="w-full">
            <label for="email">
              Email<span class="text-red-400">*</span>
            </label>
            <input
              id="email"
              v-model="form.contactEmail"
              type="email"
              class="input"
              placeholder="Enter an email"
            />
          </div>
        </div>

        <div class="w-full mt-5">
          <label for="phoneNumber">
            Phone Number<span class="text-red-400">*</span>
          </label>
          <input
            id="phoneNumber"
            v-model="form.contactPhone"
            type="number"
            class="input"
            placeholder="Enter a phone number"
            pattern="/\d*$/"
            onKeyPress="if(this.value.length==11) return false;"
          />
        </div>

        <div class="w-full mt-5">
          <label for="subject">
            Subject<span class="text-red-400">*</span>
          </label>
          <input
            id="subject"
            v-model="form.subject"
            type="text"
            class="input"
            placeholder="Subject"
            maxlength="240"
          />
        </div>

        <div class="w-full mt-5">
          <label for="message">
            Enter your message<span class="text-red-400">*</span>
          </label>
          <textarea
            id="message"
            v-model="form.message"
            type="text"
            class="textarea"
            placeholder="Type your message here"
            maxlength="450"
          ></textarea>
        </div>

        <div class="mt-7 pb-5">
          <button
            type="submit"
            class="bg-oneGreen text-white rounded-lg py-4 text-sm lg:text-2pxl font-semibold w-full flex justify-center items-center gap-4"
            :disabled="regging || !formReady"
          >
            <spinner class="" v-if="regging"></spinner>
            <span>Send Message</span>
          </button>
        </div>
      </form>
    </div>
    <AuthFooter class="mt-4" />
  </div>
</template>

<script>
import AuthHeader from "@/components/auth/Header.vue";
import AuthFooter from "@/components/auth/Footer.vue";
export default {
  name: "ContactPage",

  components: {
    AuthHeader,
    AuthFooter,
  },

  data() {
    return {
      form: {
        contactName: null,
        contactEmail: null,
        contactPhone: null,
        subject: null,
        message: null,
      },
      regging: false,
    };
  },

  computed: {
    bgImg() {
      return `@/assets/images/${this.PROJECT_NAME}-shield.png`;
    },
    emailReady() {
      return (
        (this.form.contactEmail &&
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            this.form.contactEmail
          )) ||
        !this.form.contactEmail
      );
    },
    phoneReady() {
      return (
        (this.form.contactPhone &&
          /^(\+[\d]{1,13}|0[\d]{1,11})$/.test(this.form.contactPhone)) ||
        !this.form.contactPhone
      );
    },
    formReady() {
      return (
        this.form.contactName &&
        this.form.contactEmail &&
        this.form.contactPhone &&
        this.form.subject &&
        this.form.message &&
        this.form.message.length >= 10 &&
        this.emailReady &&
        this.phoneReady
      );
    },
  },

  methods: {
    async register() {
      if (!this.formReady) {
        this.$swal({
          icon: "error",
          text: "Please fill all required input",
        });
        return;
      }
      try {
        this.regging = true;
        const res = await this.$http.post("/send-message", this.form, {
          headers: { noauth: true },
        });

        this.regging = false;
        if (!res) {
          return;
        }
        const { data } = res;
        this.$swal({
          icon: "success",
          text: data.message,
        });
        this.form.contactName = null;
        this.form.contactEmail = null;
        this.form.contactPhone = null;
        this.form.subject = null;
        this.form.message = null;
      } catch (error) {
        this.regging = false;
      }
    },
  },
};
</script>

<style scoped>
.background-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-top: 10%;
  padding: 0 4%;
}

.background-container::before {
  content: "";
  background-image: var(--bgImage);
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: -1;
}

.inquiry {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 0%;
  margin: 0%;
}

.inquirytext h3 {
  color: #1a8103;
  font-family: Playfair Display;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
}

.inquirytext h6 {
  color: #393939;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.inquiryPhone {
  margin-top: 30%;
}

.inquiryPhone p:first-child {
  color: #8d8d8d;
  font-family: Playfair Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140.3%;
}

.inquiryPhone p:last-child {
  color: #393939;
  font-family: Mulish;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140.3%;
}

.input {
  width: 100%;
}

.textarea {
  width: 100%;
  height: 150px;
}

.inquiry {
  width: 50%;
}

.form {
  width: 50%;
}

@media screen and (max-width: 800px) {
  .background-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-top: 20%;
    padding: 0 4%;
  }

  .background-container::before {
    content: "";
    /* background-image: var(--bgImage); */
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    /* Adjust the opacity value as desired (0.0 to 1.0) */
    z-index: -1;
  }

  .inquiry {
    width: 100%;
  }

  .form {
    width: 100%;
  }

  .inquiryPhone {
    margin-top: 10%;
    margin-bottom: 10%;
  }
}
</style>
