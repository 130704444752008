<template>
  <auth-layout>
    <section class="text-center">
      <h1 class="text-2pxl lg:text-2xl font-mulish font-black uppercase">
        {{ APP_NAME }} staff VERIFICATION
      </h1>
      <h5 class="text-sm lg:text-base font-semibold text-oneGray">
        Enter the OTP sent to you
      </h5>
    </section>

    <form @submit.prevent="verifyAction">
      <div class="mt-4">
        <label for="code"> Enter the code </label>
        <div class="code-div">
          <input
            id="code"
            type="text"
            class="input-code"
            v-model="form.code"
            placeholder="Enter code"
          />
          <button
            type="button"
            @click="resend()"
            :disabled="!resendready"
            class="text-oneGreen px-1 py-3"
          >
            <span v-if="resendready" class="underline text-xs lg:text-sm">
              Resend
            </span>
            <span v-else class="text-xs">00:{{ timerCount }}</span>
          </button>
        </div>
      </div>

      <div class="mt-7">
        <button
          type="submit"
          class="bg-oneGreen text-white rounded-lg py-4 text-sm lg:text-2pxl font-semibold w-full flex justify-center items-center gap-4"
          :disabled="logging"
        >
          <spinner class="" v-if="logging"></spinner>
          <span v-if="isLogin">Login</span>
          <span v-else>Verify</span>
        </button>
      </div>
    </form>
  </auth-layout>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";
const delay = 30;
export default {
  name: "VerifyOtp",

  components: {
    AuthLayout,
  },

  data() {
    return {
      form: {
        code: null,
      },
      logging: false,
      timerCount: delay,
      nextUrl: "Create Password",
    };
  },

  created() {
    const { nx } = this.$route.query;
    this.nextUrl = nx;
    this.countDownTimer();
  },

  computed: {
    resendready() {
      return this.timerCount <= 0;
    },
    isLogin() {
      return this.nextUrl !== "Create Password";
    },
  },

  methods: {
    async login() {
      try {
        this.logging = true;

        const res = await this.$http.post(
          "/employee/login-otp",
          {
            loginToken: this.form.code,
            userId: this.$store.state.general.user.id,
          },
          {
            headers: { noauth: true },
          }
        );

        this.logging = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("general/saveUser", {
          user: data.data,
          token: data.token,
        });
        this.$store.commit("general/login");

        // the go to code verification
        this.$router.push({
          name: "Staff Dashboard",
        });
      } catch (error) {
        this.logging = false;
      }
    },
    async verify() {
      try {
        this.logging = true;

        const res = await this.$http.post(
          "/auth/verify-token",
          {
            passwordCode: this.form.code,
            emailOrPhone: this.$store.state.general.user.emailOrPhone,
          },
          {
            headers: { noauth: true },
          }
        );

        this.logging = false;
        if (!res) {
          return;
        }

        const { data } = res;
        this.$store.commit("general/saveUser", {
          user: data.data,
        });

        // the go to password change page
        this.$router.push({
          name: this.nextUrl,
          query: { acn: "reset" },
        });
      } catch (error) {
        this.logging = false;
      }
    },
    async verifyAction() {
      if (this.isLogin) {
        await this.login();
        return;
      }

      await this.verify();
      return;
    },
    countDownTimer() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          this.timerCount -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    resend() {
      this.timerCount = delay;
      this.countDownTimer();
    },
  },
};
</script>

<style scoped>
form {
  @apply mx-auto w-full mt-11 lg:mt-14;
  max-width: 35rem;
}
</style>
